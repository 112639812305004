import React from "react";
import "./App.css";
import "./index.css";
import HomePage from "./components/HomePage";

function App() {
  return <HomePage />
}

export default App;
